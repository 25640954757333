import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import BannerImage from "../svg/bannerImage";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import { finalShowForm } from "@lib/sharedUtils";
import TextBlockOffer from "@components/shared/textBlockOffer/TextBlockOffer";
export default function MainBanner(): ReactElement {
    const router = useRouter();
    const { domain } = useDomainContext();
    const isFormDisplayed = finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );
    return (
        <>
            {isFormDisplayed ? (
                <div className={`mx-auto px-5  lg:max-w-7xl  }`}>
                    <div className={styles["main-banner"]}>
                        <Form
                            classes={{
                                formClassName: styles["form"],
                                stepTitleClassName: styles["step-title"],
                                stepDescriptionClassName:
                                    styles["step-description"],
                                formButtonClassName: styles["form-button"],
                                stepClassName: styles["step"],
                                formFieldClassName: styles["form-field"],
                                formStepInnerClassName: styles["steps-inner"],
                                fieldsClassName: {
                                    radio: styles["radio-field"],
                                },
                                fieldsWrapperClassName:
                                    styles["fields-wrapper"],
                                tcpaClassName: styles["tcpa"],
                                labelAsTitleClassName:
                                    styles["label-as-step-title"],
                                formProgressbarClassName:
                                    styles["progress-background"],
                            }}
                            colors={{
                                progressBar: "#b0b0b0",
                                primaryColor: "#e2048c",
                            }}
                            showProgress={true}
                        />
                        <div
                            className={`${styles.image} mb-6 w-[95%] md:w-[45%]`}
                        >
                            <BannerImage className="" />
                        </div>
                    </div>
                </div>
            ) : (
                <TextBlockOffer />
            )}
        </>
    );
}
