import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

import Banners from "./icons/Banners";
import PointOfPurchaseDisplays from "./icons/PointOfPurchaseDisplays";
import Graphics from "./icons/Graphics";
import Poster from "./icons/Poster";
import Yard from "./icons/Yard";
import TradshowSignage from "./icons/TradshowSignage";
import FeatureList from "@components/shared/featureList";

const benefitsList = [
    {
        title: "Banners",
        icon: <Banners />,
    },
    {
        title: "Point of Purchase Displays",
        icon: <PointOfPurchaseDisplays />,
    },
    {
        title: "Wall and Floor Graphics",
        icon: <Graphics />,
    },
    {
        title: "Posters",
        icon: <Poster />,
    },
    {
        title: "Yard Signs",
        icon: <Yard />,
    },
    {
        title: "Tradshow Signage",
        icon: <TradshowSignage />,
    },
];

export default function Benefits(): ReactElement {
    return (
        <div
            className={`py-5 lg:py-4 px-5 flex lg:max-w-7xl mx-auto lg:px-6  flex-col xl:flex-row flex-nowrap items-center justify-between mb-12 ${styles["DoYouKnowWrapper"]}`}
        >
            <div className="w-full flex flex-col align-center justify-center">
                <FeatureList
                    featureList={benefitsList}
                    mainTitle="Optimal Products for Large Format Printing"
                    mainDescription="The versatility of large format printing lies in its suitability for a broad spectrum of products. Commonly utilized for an array of items, large format printing excels in enhancing visual appeal and delivering impactful messages. Among these, the most notable products include:"
                    classes={{
                        mainSectionClasses: "-mt-14",
                        mainTitleClasses:
                            "font-bold text-center mb-8 text-2xl xl:text-2xl pt-11 md:pt-24",
                        mainDescriptionClasses:
                            "text-base text-center lg:text-lg mb-6",
                        innerSectionClasses:
                            "flex flex-row flex-wrap justify-around items-center",
                        oneBoxClasses:
                            "flex w-1/2 md:w-1/3 p-3 flex-col justify-center items-center my-10",
                        iconClasses: "mb-6",
                        titleClasses: "font-bold text-center",
                    }}
                    colors={{
                        mainDescriptionColor: "#686868",
                    }}
                />

                <div className="flex flex-col lg:flex-row justify-between items-center">
                    <div className="lg:w-1/2">
                        <h2 className="font-bold text-2xl mb-8">
                            Printer Types for Large Format Printing
                        </h2>
                        <p
                            className="text-lg font-light"
                            style={{ color: "#6a6a6a" }}
                        >
                            When it comes to large format printing, there are
                            mainly three types of printers in use: flatbed
                            printers, roll-to-roll printers, or hybrids that
                            combine both functions. Flatbed printers work by
                            placing the material, or substrate, onto a bed,
                            which then moves it through the printer. In this
                            process, UV light cures the liquid ink, transforming
                            it into a solid form on the substrate.
                            <br />
                            Roll-to-roll printers, on the other hand,
                            roll-to-roll printers are ideal for printing
                            banners, canvas, and other flexible materials
                            supplied on rolls. These printers feed the roll
                            through the machine, where it's printed and then
                            re-rolled at the other end. The combination printers
                            offer the best of both worlds, featuring a removable
                            flatbed and the capability to attach rolls, thus
                            enabling both printing methods. This versatility
                            makes them a smart choice for diverse large format
                            printing needs.
                        </p>
                    </div>
                    <img src="/assets/images/bannersOpt.png" alt="" />
                </div>
            </div>
        </div>
    );
}
