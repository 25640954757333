import React from "react";

function Yard() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="103"
            height="130"
            fill="none"
            viewBox="0 0 103 130"
        >
            <path
                fill="#F0F8FF"
                d="M32.011 26.976H80.011V56.976H32.011z"
            ></path>
            <path
                fill="#D6ECFF"
                d="M74.01 49.976l5-21 1.5 27-42.5-1 36-5z"
            ></path>
            <path
                fill="#168FCE"
                d="M9.799 5.104c-.925 0-1.83.273-2.598.784a4.658 4.658 0 00-1.723 2.088 4.631 4.631 0 001.014 5.071 4.698 4.698 0 005.096 1.009 4.672 4.672 0 002.099-1.714 4.636 4.636 0 00.788-2.585 4.646 4.646 0 00-1.371-3.289 4.694 4.694 0 00-3.305-1.364zm0 12.41a7.823 7.823 0 01-4.332-1.306 7.766 7.766 0 01-2.873-3.48 7.72 7.72 0 01-.445-4.483 7.745 7.745 0 012.133-3.972 7.808 7.808 0 013.992-2.124 7.832 7.832 0 014.505.441 7.788 7.788 0 013.499 2.857 7.73 7.73 0 011.314 4.31 7.742 7.742 0 01-2.284 5.482 7.821 7.821 0 01-5.51 2.275zM16.501 119.4a2.105 2.105 0 00-1.94 1.297 2.098 2.098 0 101.94-1.297zm0 5.6a3.505 3.505 0 01-3.234-2.16 3.499 3.499 0 014.572-4.574A3.505 3.505 0 0120 121.5a3.502 3.502 0 01-3.499 3.5z"
            ></path>
            <path
                fill="#E2048C"
                d="M19.975 86.322a201.308 201.308 0 01-2.418-1.326 2.69 2.69 0 01-1.066-1.061c-.25-.443-1.139-2.077-1.333-2.406a1.033 1.033 0 00-.904-.529 1.039 1.039 0 00-.904.529c-.184.329-1.066 1.963-1.333 2.406a2.689 2.689 0 01-1.067 1.06l-2.418 1.327A1.026 1.026 0 008 87.22a1.02 1.02 0 00.532.897c.336.18 1.973 1.077 2.418 1.326.45.246.82.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.03 1.03 0 00.904.532 1.037 1.037 0 00.904-.532c.184-.326 1.066-1.963 1.333-2.406a2.705 2.705 0 011.066-1.061c.445-.241 2.083-1.138 2.418-1.326a1.027 1.027 0 00.533-.897 1.02 1.02 0 00-.533-.897z"
            ></path>
            <path
                fill="#168FCE"
                d="M101.975 35.987c-.251-.136-1.48-.81-1.816-.99a1.999 1.999 0 01-.8-.796c-.18-.334-.85-1.562-.991-1.806a.777.777 0 00-.679-.395.781.781 0 00-.678.395c-.142.244-.8 1.472-.995 1.806a1.974 1.974 0 01-.8.796c-.336.18-1.565.855-1.816.99a.77.77 0 00-.4.672.765.765 0 00.4.673l1.816.992c.34.181.618.458.8.796.184.331.853 1.562.995 1.804a.773.773 0 00.678.397.78.78 0 00.679-.397c.141-.242.8-1.473.992-1.804.183-.337.46-.613.799-.796l1.816-.992a.768.768 0 000-1.345zM14.975 52.987c-.25-.136-1.48-.81-1.816-.99a1.998 1.998 0 01-.8-.796c-.18-.334-.85-1.562-.991-1.806a.777.777 0 00-.679-.395.781.781 0 00-.678.395c-.142.244-.8 1.472-.995 1.806a1.974 1.974 0 01-.8.796c-.336.18-1.565.855-1.815.99a.769.769 0 00-.401.672.764.764 0 00.4.673l1.816.992c.34.181.618.458.8.796.184.331.853 1.562.995 1.804a.773.773 0 00.678.397.78.78 0 00.679-.397c.141-.242.8-1.473.992-1.804a1.98 1.98 0 01.8-.796l1.815-.992a.77.77 0 00.29-1.063.77.77 0 00-.29-.282zM87.975 112.987c-.25-.135-1.48-.809-1.816-.99a1.992 1.992 0 01-.8-.796c-.18-.334-.85-1.562-.991-1.806a.773.773 0 00-.679-.395.785.785 0 00-.678.395c-.142.244-.8 1.472-.995 1.806a1.967 1.967 0 01-.8.796c-.336.181-1.565.855-1.816.99a.77.77 0 00-.4.672.763.763 0 00.4.673l1.816.992c.34.181.618.458.8.796.184.331.853 1.562.995 1.804a.777.777 0 001.357 0c.141-.242.8-1.473.992-1.804.183-.337.46-.613.8-.796l1.815-.992a.77.77 0 000-1.345zM81.523 65c-.895 0-1.77.264-2.513.758a4.505 4.505 0 00-1.666 2.02 4.48 4.48 0 00.98 4.904 4.543 4.543 0 004.929.975A4.517 4.517 0 0085.283 72a4.485 4.485 0 00-.562-5.682A4.534 4.534 0 0081.523 65zM32.338 2c-.462 0-.914.136-1.299.392a2.316 2.316 0 00-.354 3.58 2.342 2.342 0 002.548.504 2.336 2.336 0 001.05-.857 2.318 2.318 0 00-.291-2.938A2.344 2.344 0 0032.338 2z"
            ></path>
            <path
                fill="#000"
                d="M92.56 54a1.565 1.565 0 00-1.441.958 1.545 1.545 0 00.338 1.691 1.563 1.563 0 002.662-1.097c0-.412-.164-.806-.456-1.097A1.564 1.564 0 0092.56 54zM81.56 84a1.566 1.566 0 00-1.442.959 1.544 1.544 0 00.34 1.691 1.562 1.562 0 002.662-1.1 1.543 1.543 0 00-.964-1.433A1.565 1.565 0 0081.56 84zM26.56 101a1.562 1.562 0 00-1.441.958 1.546 1.546 0 00.338 1.691 1.564 1.564 0 002.4-.235 1.545 1.545 0 00-.194-1.959A1.564 1.564 0 0026.56 101z"
            ></path>
            <path
                fill="#D6ECFF"
                d="M14.122 26a3.13 3.13 0 00-1.734.522c-.513.34-.914.826-1.15 1.393a3.089 3.089 0 00.676 3.384 3.124 3.124 0 003.4.672 3.116 3.116 0 001.4-1.144 3.093 3.093 0 00-.388-3.919A3.125 3.125 0 0014.122 26z"
            ></path>
            <path
                fill="#E2048C"
                d="M100.975 97.322a200.637 200.637 0 01-2.418-1.326 2.69 2.69 0 01-1.066-1.061c-.25-.443-1.139-2.077-1.333-2.406a1.033 1.033 0 00-.904-.529 1.039 1.039 0 00-.904.529c-.184.329-1.066 1.963-1.333 2.406a2.689 2.689 0 01-1.067 1.06l-2.418 1.327a1.026 1.026 0 00-.532.897 1.02 1.02 0 00.532.897c.336.18 1.973 1.077 2.418 1.326.45.246.82.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.034 1.034 0 001.808 0c.184-.326 1.066-1.963 1.333-2.406a2.703 2.703 0 011.066-1.061c.445-.241 2.082-1.138 2.418-1.326a1.026 1.026 0 00.533-.897 1.026 1.026 0 00-.533-.897zM91.975 5.322a201.604 201.604 0 01-2.418-1.326 2.69 2.69 0 01-1.066-1.061c-.25-.443-1.139-2.077-1.333-2.406A1.032 1.032 0 0086.254 0a1.038 1.038 0 00-.904.529c-.184.329-1.066 1.963-1.333 2.406a2.688 2.688 0 01-1.067 1.06l-2.418 1.327A1.027 1.027 0 0080 6.22a1.02 1.02 0 00.532.897c.336.18 1.973 1.077 2.418 1.326.45.246.82.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.03 1.03 0 00.904.532 1.037 1.037 0 00.904-.532c.184-.326 1.066-1.963 1.333-2.406a2.705 2.705 0 011.066-1.061c.445-.241 2.082-1.138 2.418-1.326a1.027 1.027 0 00.533-.897 1.02 1.02 0 00-.533-.897z"
            ></path>
            <path
                fill="#000"
                d="M1.56 38a1.566 1.566 0 00-1.441.958 1.545 1.545 0 00.338 1.691 1.563 1.563 0 002.662-1.097c0-.412-.164-.806-.456-1.097A1.564 1.564 0 001.56 38z"
            ></path>
            <path
                fill="#D6ECFF"
                d="M29.5 71.8a2.7 2.7 0 100 5.4 2.7 2.7 0 000-5.4zm0 7.2a4.5 4.5 0 110-9 4.5 4.5 0 010 9z"
            ></path>
            <path
                fill="#168FCE"
                d="M95.501 15.4a2.1 2.1 0 100 4.2 2.1 2.1 0 000-4.2zm0 5.6a3.5 3.5 0 11-.002-7 3.5 3.5 0 01.002 7z"
            ></path>
            <path
                fill="#000"
                d="M66.677 129.976c-.07.004-.14.004-.21 0-.476-.034-1.567-.122-1.567-2.321V75.214c0-4.259-.444-4.7-4.676-4.705H51.58c-2.383 0-4.29 0-6.1-.063h-.22c-2.383 0-3.555 1.073-3.665 3.399v1.267c.072 7.283.043 31.513.033 44.521v6.356l-.028.131v.107c.01.52-.024 1.04-.105 1.554-.415 2.175-1.582 2.175-1.964 2.175-.07.005-.14.005-.21 0-.476-.034-1.567-.121-1.567-2.321V75.442l.028-.053v-11.61c0-3.948-.476-4.448-4.46-4.458h-8.103c-1.13 0-2.54 0-2.86-.32-.319-.32-.314-1.811-.314-3.01V23.253v-.971c0-.729.029-1.486-.028-2.244-.058-.757.043-1.301.295-1.578.253-.276.882-.461 1.797-.461h.067c3.965.034 8.145.049 13.559.049h44.08c.543 0 1.82 0 2.002.184.18.185.176 1.457.176 1.99v36.88c0 .616 0 1.898-.172 2.068-.171.17-1.477.184-2.173.19h-8.579c-3.813 0-4.332.538-4.337 4.37v62.098a10.66 10.66 0 01-.11 2.025c-.395 2.122-1.563 2.122-1.944 2.122zM53.132 66.561c2.817 0 5.72 0 8.55.039h.053c3.17 0 3.179-2.675 3.184-3.554 0-1.01-.096-2.146-.853-2.914A3.072 3.072 0 0063 59.487a3.03 3.03 0 00-1.228-.166h-.186c-.953.034-2.02.044-3.303.044H48.276c-1.282 0-2.335 0-3.308-.044h-.195a3.067 3.067 0 00-1.254.169 3.111 3.111 0 00-1.086.662c-.767.796-.853 1.942-.839 2.87.014.927.048 3.588 3.136 3.588h.053c2.754-.034 5.561-.049 8.35-.049zM66 55.393c4.066 0 7.354 0 10.357.058h.124c.824 0 2.02-.097 2.83-.927.81-.83.906-2.054.897-3.02-.09-8.629-.09-17.272 0-25.682 0-.869-.077-2.117-.896-2.962-.82-.844-2.007-.947-2.95-.947-7.788.03-15.585.05-23.21.05-7.626 0-15.423 0-22.996-.05-.896 0-2.126.098-2.945.943-.82.845-.91 2.053-.901 3.015.08 8.828.08 17.48 0 25.681 0 .87.071 2.117.891 2.962.82.845 1.968.933 2.893.933h.124c3.017-.034 6.334-.049 10.428-.049L66 55.393z"
            ></path>
        </svg>
    );
}

export default Yard;
