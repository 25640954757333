import React from "react";

function Banners() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="126"
            height="150"
            fill="none"
            viewBox="0 0 126 150"
        >
            <path fill="#F0F8FF" d="M41 10H96V139H41z"></path>
            <path fill="#D6ECFF" d="M86 133.5L95 15v124H47l39-5.5z"></path>
            <path
                fill="#168FCE"
                d="M11.799 16.104c-.925 0-1.83.273-2.598.784a4.658 4.658 0 00-1.723 2.088 4.631 4.631 0 001.014 5.071 4.698 4.698 0 005.096 1.009 4.672 4.672 0 002.099-1.714 4.636 4.636 0 00.788-2.585 4.646 4.646 0 00-1.371-3.289 4.694 4.694 0 00-3.305-1.364zm0 12.41a7.823 7.823 0 01-4.332-1.306 7.766 7.766 0 01-2.873-3.48 7.72 7.72 0 01-.445-4.483 7.745 7.745 0 012.133-3.972 7.809 7.809 0 013.992-2.124 7.833 7.833 0 014.505.441 7.788 7.788 0 013.499 2.857 7.73 7.73 0 011.314 4.31 7.742 7.742 0 01-2.284 5.482 7.821 7.821 0 01-5.51 2.275zM116.501 57.4a2.098 2.098 0 00-1.484 3.585 2.094 2.094 0 002.288.455 2.104 2.104 0 001.296-1.94 2.102 2.102 0 00-2.1-2.1zm0 5.6a3.502 3.502 0 01-3.434-4.182 3.497 3.497 0 012.75-2.75 3.498 3.498 0 013.158 5.906A3.504 3.504 0 01116.501 63z"
            ></path>
            <path
                fill="#E2048C"
                d="M20.975 95.322a201.308 201.308 0 01-2.418-1.326 2.69 2.69 0 01-1.066-1.061c-.25-.443-1.139-2.077-1.333-2.406a1.033 1.033 0 00-.904-.529 1.039 1.039 0 00-.904.529c-.184.329-1.066 1.963-1.333 2.406a2.689 2.689 0 01-1.067 1.06l-2.418 1.327A1.026 1.026 0 009 96.22a1.02 1.02 0 00.532.897c.336.18 1.973 1.077 2.418 1.326.45.246.82.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.034 1.034 0 001.808 0c.184-.326 1.066-1.963 1.333-2.406a2.705 2.705 0 011.066-1.061c.445-.241 2.083-1.138 2.418-1.326a1.027 1.027 0 00.533-.897 1.02 1.02 0 00-.533-.897z"
            ></path>
            <path
                fill="#168FCE"
                d="M121.975 25.987c-.251-.135-1.48-.81-1.816-.99a2.002 2.002 0 01-.8-.796c-.181-.334-.85-1.562-.991-1.806a.782.782 0 00-1.357 0c-.142.244-.8 1.472-.995 1.806a1.972 1.972 0 01-.8.796c-.336.18-1.565.855-1.815.99a.76.76 0 00-.401.672.76.76 0 00.401.673l1.815.992c.34.181.618.458.8.796.184.331.853 1.562.995 1.804a.773.773 0 00.678.397.778.778 0 00.679-.397c.141-.242.8-1.473.991-1.804.184-.337.462-.614.8-.796l1.816-.992a.768.768 0 000-1.345zM8.975 79.987c-.25-.135-1.48-.81-1.816-.99a1.997 1.997 0 01-.8-.796c-.18-.334-.85-1.562-.991-1.806A.777.777 0 004.689 76a.781.781 0 00-.678.395c-.142.244-.8 1.472-.995 1.806a1.974 1.974 0 01-.8.796c-.336.18-1.565.855-1.815.99a.769.769 0 00-.401.672.764.764 0 00.4.673l1.816.992c.34.181.618.458.8.796.184.331.853 1.562.995 1.804a.773.773 0 00.678.397.78.78 0 00.679-.397c.141-.242.8-1.473.991-1.804.184-.337.462-.614.8-.796l1.816-.992a.77.77 0 00.29-1.063.77.77 0 00-.29-.282zM19.523 117c-.895 0-1.77.264-2.513.758a4.51 4.51 0 00-1.666 2.02 4.479 4.479 0 00.98 4.904 4.536 4.536 0 006.96-.682 4.484 4.484 0 00-.563-5.682A4.534 4.534 0 0019.523 117zM104.338 75a2.332 2.332 0 00-2.16 1.436 2.317 2.317 0 00.507 2.536 2.344 2.344 0 003.597-.353 2.319 2.319 0 00-.29-2.938 2.347 2.347 0 00-1.654-.681z"
            ></path>
            <path
                fill="#000"
                d="M105.56 34a1.556 1.556 0 00-1.53 1.855 1.55 1.55 0 001.225 1.219 1.569 1.569 0 001.602-.66 1.548 1.548 0 00-.194-1.96A1.566 1.566 0 00105.56 34zM7.56 54a1.566 1.566 0 00-1.441.958 1.545 1.545 0 00.338 1.691 1.563 1.563 0 002.662-1.097c0-.412-.164-.806-.456-1.097A1.564 1.564 0 007.56 54zM105.56 103a1.561 1.561 0 00-1.442.959 1.547 1.547 0 001.139 2.115 1.56 1.56 0 001.862-1.525A1.55 1.55 0 00105.56 103zM1.56 93a1.565 1.565 0 00-1.441.958 1.545 1.545 0 00.338 1.691 1.563 1.563 0 002.662-1.097c0-.412-.164-.806-.456-1.097A1.564 1.564 0 001.56 93z"
            ></path>
            <path
                fill="#D6ECFF"
                d="M7.122 36a3.13 3.13 0 00-1.734.522c-.513.34-.914.826-1.15 1.393a3.09 3.09 0 00.676 3.384 3.124 3.124 0 003.4.672 3.117 3.117 0 001.4-1.144 3.093 3.093 0 00-.388-3.919A3.125 3.125 0 007.122 36zM5.122 106a3.126 3.126 0 00-2.884 1.915 3.089 3.089 0 00.676 3.384 3.128 3.128 0 003.4.672 3.12 3.12 0 001.4-1.144 3.095 3.095 0 00-.388-3.919A3.127 3.127 0 005.122 106z"
            ></path>
            <path
                fill="#000"
                d="M100.663 3.983V0H25v3.983h3.318V138.05H25v3.984h15.946l-5.326 7.963h3.983l5.31-7.963H80.75l5.31 7.963h3.983l-5.31-7.963h15.929v-3.984h-3.318V3.983h3.318zM93.365 138.05h-61.07V3.983h61.07V138.05z"
            ></path>
            <path
                fill="#D6ECFF"
                d="M113.796 117.104c-.925 0-1.829.273-2.598.784a4.636 4.636 0 00-.709 7.159 4.684 4.684 0 002.395 1.274 4.705 4.705 0 002.702-.265 4.674 4.674 0 002.098-1.714 4.635 4.635 0 00-.583-5.874 4.693 4.693 0 00-3.305-1.364zm0 12.41a7.825 7.825 0 01-4.331-1.307 7.77 7.77 0 01-2.872-3.482 7.717 7.717 0 01-.443-4.481 7.748 7.748 0 012.133-3.972 7.81 7.81 0 013.992-2.123 7.834 7.834 0 014.504.441 7.798 7.798 0 013.499 2.857 7.73 7.73 0 011.314 4.31 7.738 7.738 0 01-2.285 5.483 7.822 7.822 0 01-5.511 2.274zM19 49.6a2.4 2.4 0 100 4.8 2.4 2.4 0 000-4.8zm0 6.4a4 4 0 110-8 4 4 0 010 8z"
            ></path>
            <path
                fill="#E2048C"
                d="M124.975 90.322a199.073 199.073 0 01-2.418-1.326 2.681 2.681 0 01-1.066-1.061c-.251-.443-1.139-2.077-1.333-2.406a1.035 1.035 0 00-1.808 0c-.184.329-1.067 1.963-1.333 2.406a2.69 2.69 0 01-1.067 1.06l-2.418 1.327a1.03 1.03 0 00-.532.897 1.016 1.016 0 00.532.897c.336.18 1.973 1.077 2.418 1.326.45.246.819.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.029 1.029 0 00.904.532 1.039 1.039 0 00.904-.532c.184-.326 1.066-1.963 1.333-2.406a2.702 2.702 0 011.066-1.061c.445-.241 2.082-1.138 2.418-1.326a1.026 1.026 0 00.533-.897 1.026 1.026 0 00-.533-.897zM106.975 15.322a199.073 199.073 0 01-2.418-1.326 2.681 2.681 0 01-1.066-1.061c-.251-.443-1.139-2.077-1.333-2.406a1.035 1.035 0 00-1.808 0c-.184.329-1.066 1.963-1.333 2.406a2.689 2.689 0 01-1.067 1.06l-2.418 1.327a1.026 1.026 0 00-.532.897 1.02 1.02 0 00.532.897c.336.18 1.973 1.077 2.418 1.326.45.246.82.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.029 1.029 0 00.904.532 1.039 1.039 0 00.904-.532c.184-.326 1.066-1.963 1.333-2.406a2.702 2.702 0 011.066-1.061c.445-.241 2.082-1.138 2.418-1.326a1.026 1.026 0 00.533-.897 1.026 1.026 0 00-.533-.897z"
            ></path>
        </svg>
    );
}

export default Banners;
