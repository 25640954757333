import React from "react";

function Poster() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="120"
            height="129"
            fill="none"
            viewBox="0 0 120 129"
        >
            <g clipPath="url(#clip0_48_7)">
                <path fill="#F0F8FF" d="M31 31H90V113H31z"></path>
                <path
                    fill="#D6ECFF"
                    d="M83.438 87.667L89.561 41 90 97H48l35.438-9.333z"
                ></path>
                <path
                    fill="#000"
                    d="M63.407 116c-8.877 0-17.754-.006-26.631-.019-8.079 0-13.732-5.613-13.748-13.624-.037-19.706-.028-39.74-.019-59.117V25.998c0-1.914.338-2.256 2.26-2.269H32.33l.047-.888c0-.229.029-.436.041-.627.026-.405.051-.757.051-1.124v-6.417c0-2.488.174-2.66 2.596-2.664h8.483c1.337 0 2.839 0 3.205.355.366.355.36 1.867.36 3.2v8.08h20.791v-9.121c0-2.25.256-2.51 2.448-2.51h9.584c2.46 0 2.66.194 2.662 2.598v9.124h6.798c2.423 0 2.644.226 2.644 2.646v70.353H98.085c1.328 0 2.417 0 3.423-.05h.202c1.199 0 1.851.367 2.277 1.296v5.334c-.019.066-.034.132-.053.197-.077.267-.14.537-.19.81-1.119 7.182-6.309 11.648-13.564 11.657-8.913.017-17.837.024-26.773.022zM49.77 100.452a58.381 58.381 0 00-.209 1.961 21.292 21.292 0 01-.63 3.973 20.905 20.905 0 01-1.616 3.649c-.29.562-.593 1.145-.873 1.741l-.606 1.278 1.42.062h.236c.224 0 .48.029.748.029h24.315c7.006 0 12.754 0 18.08-.022 1.588.001 3.156-.36 4.584-1.055a10.403 10.403 0 003.649-2.956 10.193 10.193 0 001.969-4.174c.328-1.523.302-3.1-.077-4.611l-.176-.716h-50.71l-.104.841zm-23.915-62.05v63.308c-.006.764.031 1.528.113 2.288a10.27 10.27 0 003.346 6.504 10.372 10.372 0 006.844 2.655h.066c5.34 0 9.669-3.765 10.293-8.955.13-1.3.184-2.607.161-3.913v-1.032c.026-2.388.17-2.53 2.574-2.533h39.903V26.663H25.852l.003 11.739zm45.01-14.748h8.832v-8.708h-8.833v8.708zm-35.42 0h8.757v-8.701h-8.757v8.7z"
                ></path>
                <path
                    fill="#000"
                    d="M60.038 81.857H45.275c-1.95 0-1.956-.966-1.96-1.377a1.272 1.272 0 01.332-.942c.315-.313.864-.48 1.577-.48h29.684a2.177 2.177 0 011.578.512 1.255 1.255 0 01.334.941c-.025.895-.653 1.346-1.867 1.35H68.4l-8.363-.004zM59.416 66.564H39.284a2.645 2.645 0 01-2.306-1.325 2.602 2.602 0 010-2.648l10.066-17.343a2.65 2.65 0 012.306-1.324 2.648 2.648 0 012.306 1.324l10.066 17.343a2.603 2.603 0 010 2.648 2.639 2.639 0 01-2.306 1.324zm-19.83-2.824h19.528l-9.78-16.82-9.747 16.82zM68.382 66.564a2.639 2.639 0 01-2.306-1.325L56.009 47.897a2.604 2.604 0 010-2.649 2.648 2.648 0 012.306-1.324h20.133a2.649 2.649 0 012.306 1.324 2.603 2.603 0 010 2.649L70.688 65.239a2.621 2.621 0 01-2.306 1.325zm-9.78-19.819l9.78 16.819 9.763-16.819H58.603z"
                ></path>
            </g>
            <path
                fill="#168FCE"
                d="M12.799 13.104c-.925 0-1.83.273-2.598.784a4.658 4.658 0 00-1.723 2.088 4.631 4.631 0 001.014 5.071 4.698 4.698 0 005.096 1.009 4.672 4.672 0 002.099-1.714 4.636 4.636 0 00.788-2.585 4.646 4.646 0 00-1.371-3.289 4.694 4.694 0 00-3.305-1.364zm0 12.41a7.823 7.823 0 01-4.332-1.306 7.766 7.766 0 01-2.873-3.48 7.72 7.72 0 01-.445-4.483 7.745 7.745 0 012.133-3.972 7.809 7.809 0 013.992-2.124 7.833 7.833 0 014.505.441 7.788 7.788 0 013.499 2.857 7.73 7.73 0 011.314 4.31 7.742 7.742 0 01-2.284 5.482 7.821 7.821 0 01-5.51 2.275zM7.501 120.4a2.105 2.105 0 00-1.94 1.297 2.098 2.098 0 003.686 1.969A2.098 2.098 0 007.5 120.4zm0 5.6a3.505 3.505 0 01-3.234-2.16 3.499 3.499 0 014.572-4.574A3.505 3.505 0 0111 122.5a3.502 3.502 0 01-3.499 3.5z"
            ></path>
            <path
                fill="#E2048C"
                d="M11.975 94.322a201.383 201.383 0 01-2.418-1.326 2.69 2.69 0 01-1.066-1.061c-.25-.443-1.139-2.077-1.333-2.406A1.032 1.032 0 006.254 89a1.039 1.039 0 00-.904.529c-.184.329-1.067 1.963-1.333 2.406a2.69 2.69 0 01-1.067 1.06L.532 94.323A1.026 1.026 0 000 95.22a1.02 1.02 0 00.532.897c.336.18 1.973 1.077 2.418 1.326.45.246.82.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.033 1.033 0 001.808 0c.184-.326 1.066-1.963 1.333-2.406a2.705 2.705 0 011.066-1.061c.445-.241 2.082-1.138 2.418-1.326a1.027 1.027 0 00.533-.897 1.02 1.02 0 00-.533-.897z"
            ></path>
            <path
                fill="#168FCE"
                d="M112.975 33.987c-.251-.136-1.48-.81-1.816-.99a2.002 2.002 0 01-.8-.796c-.181-.334-.85-1.562-.991-1.806a.782.782 0 00-1.357 0c-.142.244-.8 1.472-.995 1.806a1.972 1.972 0 01-.8.796c-.336.18-1.565.855-1.815.99a.76.76 0 00-.401.672.76.76 0 00.401.673l1.815.992c.34.181.618.458.8.796.184.331.853 1.562.995 1.804a.773.773 0 00.678.397.778.778 0 00.679-.397c.141-.242.8-1.473.991-1.804.184-.337.462-.613.8-.796l1.816-.992a.768.768 0 000-1.345zM11.975 62.987c-.25-.136-1.48-.81-1.816-.99a1.997 1.997 0 01-.8-.796c-.18-.334-.85-1.562-.991-1.806A.777.777 0 007.689 59a.781.781 0 00-.678.395c-.142.244-.8 1.472-.995 1.806a1.974 1.974 0 01-.8.796c-.336.18-1.565.855-1.815.99a.769.769 0 00-.401.672.764.764 0 00.4.673l1.816.992c.34.181.618.458.8.796.184.331.853 1.562.995 1.804a.773.773 0 00.678.397.78.78 0 00.679-.397c.141-.242.8-1.473.991-1.804.184-.337.462-.614.8-.796l1.816-.992a.77.77 0 00.29-1.063.77.77 0 00-.29-.282zM96.975 122.987c-.25-.135-1.48-.809-1.816-.99a1.992 1.992 0 01-.8-.796c-.18-.334-.85-1.562-.991-1.806a.773.773 0 00-.679-.395.785.785 0 00-.678.395c-.142.244-.8 1.472-.995 1.806a1.967 1.967 0 01-.8.796c-.336.181-1.565.855-1.816.99a.77.77 0 00-.4.672.763.763 0 00.4.673l1.816.992c.34.181.618.458.8.796.184.331.853 1.562.995 1.804a.777.777 0 001.357 0c.141-.242.8-1.473.992-1.804.183-.337.46-.613.8-.796l1.815-.992a.77.77 0 000-1.345zM102.523 45a4.54 4.54 0 00-2.513.758 4.505 4.505 0 00-1.666 2.02 4.48 4.48 0 00.98 4.904 4.542 4.542 0 004.928.975A4.515 4.515 0 00106.284 52a4.484 4.484 0 00-.562-5.682A4.537 4.537 0 00102.523 45zM109.338 74a2.332 2.332 0 00-2.16 1.436 2.317 2.317 0 00.507 2.536 2.344 2.344 0 003.597-.353 2.319 2.319 0 00-.29-2.938 2.347 2.347 0 00-1.654-.681z"
            ></path>
            <path
                fill="#000"
                d="M107.56 59a1.556 1.556 0 00-1.53 1.855 1.55 1.55 0 001.225 1.219 1.569 1.569 0 001.602-.66 1.548 1.548 0 00-.194-1.96A1.566 1.566 0 00107.56 59zM102.56 86a1.564 1.564 0 00-1.442.959 1.548 1.548 0 00.34 1.691 1.558 1.558 0 001.7.335c.285-.118.529-.317.7-.573a1.543 1.543 0 00.143-1.456A1.558 1.558 0 00102.56 86zM12.56 109a1.562 1.562 0 00-1.441.958 1.546 1.546 0 00.338 1.691 1.564 1.564 0 002.4-.235 1.545 1.545 0 00-.194-1.959A1.564 1.564 0 0012.56 109z"
            ></path>
            <path
                fill="#D6ECFF"
                d="M16.122 35a3.13 3.13 0 00-1.734.522c-.513.34-.914.826-1.15 1.393a3.089 3.089 0 00.676 3.384 3.124 3.124 0 003.4.672 3.116 3.116 0 001.4-1.144 3.093 3.093 0 00-.388-3.919A3.125 3.125 0 0016.122 35z"
            ></path>
            <path
                fill="#E2048C"
                d="M118.975 102.322a189.644 189.644 0 01-2.418-1.326 2.686 2.686 0 01-1.066-1.061c-.251-.443-1.139-2.077-1.333-2.406a1.035 1.035 0 00-1.808 0c-.184.329-1.067 1.963-1.333 2.406a2.694 2.694 0 01-1.067 1.061l-2.418 1.326a1.019 1.019 0 000 1.794c.336.18 1.973 1.077 2.418 1.326.45.246.819.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.033 1.033 0 001.808 0c.184-.326 1.066-1.963 1.333-2.406a2.7 2.7 0 011.066-1.061c.445-.241 2.082-1.138 2.418-1.326a1.028 1.028 0 00.39-1.418 1.035 1.035 0 00-.39-.376zM96.975 5.322a201.604 201.604 0 01-2.418-1.326 2.69 2.69 0 01-1.066-1.061c-.25-.443-1.139-2.077-1.333-2.406A1.032 1.032 0 0091.254 0a1.038 1.038 0 00-.904.529c-.184.329-1.066 1.963-1.333 2.406a2.688 2.688 0 01-1.067 1.06l-2.418 1.327A1.027 1.027 0 0085 6.22a1.02 1.02 0 00.532.897c.336.18 1.973 1.077 2.418 1.326.45.246.82.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.03 1.03 0 00.904.532 1.037 1.037 0 00.904-.532c.184-.326 1.066-1.963 1.333-2.406a2.705 2.705 0 011.066-1.061c.445-.241 2.082-1.138 2.418-1.326a1.027 1.027 0 00.533-.897 1.02 1.02 0 00-.533-.897z"
            ></path>
            <path
                fill="#000"
                d="M7.56 47a1.566 1.566 0 00-1.441.958 1.545 1.545 0 00.338 1.691 1.563 1.563 0 002.662-1.097c0-.412-.164-.806-.456-1.097A1.564 1.564 0 007.56 47z"
            ></path>
            <path
                fill="#D6ECFF"
                d="M15.5 80.8a2.7 2.7 0 100 5.4 2.7 2.7 0 000-5.4zm0 7.2a4.5 4.5 0 110-9 4.5 4.5 0 010 9z"
            ></path>
            <path
                fill="#168FCE"
                d="M103.501 15.4a2.098 2.098 0 00-1.484 3.585 2.094 2.094 0 002.288.455 2.104 2.104 0 001.296-1.94 2.102 2.102 0 00-2.1-2.1zm0 5.6a3.502 3.502 0 01-3.434-4.182 3.497 3.497 0 012.75-2.75 3.498 3.498 0 013.158 5.906A3.504 3.504 0 01103.501 21z"
            ></path>
            <defs>
                <clipPath id="clip0_48_7">
                    <path
                        fill="#fff"
                        d="M0 0H81V104H0z"
                        transform="translate(23 12)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Poster;
