import React from "react";

function Graphics() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="117"
            height="150"
            fill="none"
            viewBox="0 0 117 150"
        >
            <path
                fill="#168FCE"
                d="M10.799 24.104c-.925 0-1.83.273-2.598.784a4.658 4.658 0 00-1.723 2.088 4.631 4.631 0 001.014 5.071 4.698 4.698 0 005.096 1.009 4.672 4.672 0 002.099-1.714 4.636 4.636 0 00.788-2.585 4.646 4.646 0 00-1.371-3.289 4.694 4.694 0 00-3.305-1.364zm0 12.41a7.823 7.823 0 01-4.332-1.306 7.766 7.766 0 01-2.873-3.48 7.72 7.72 0 01-.445-4.483 7.745 7.745 0 012.133-3.972 7.809 7.809 0 013.992-2.124 7.833 7.833 0 014.505.441 7.788 7.788 0 013.499 2.857 7.73 7.73 0 011.314 4.31 7.742 7.742 0 01-2.284 5.482 7.821 7.821 0 01-5.51 2.275zM109.501 27.4a2.098 2.098 0 00-1.484 3.585 2.094 2.094 0 002.288.455 2.104 2.104 0 001.296-1.94 2.102 2.102 0 00-2.1-2.1zm0 5.6a3.502 3.502 0 01-3.434-4.182 3.497 3.497 0 012.75-2.75 3.498 3.498 0 013.158 5.906A3.504 3.504 0 01109.501 33z"
            ></path>
            <path
                fill="#E2048C"
                d="M11.975 107.322a191.74 191.74 0 01-2.418-1.326 2.695 2.695 0 01-1.066-1.061c-.25-.443-1.139-2.077-1.333-2.406a1.028 1.028 0 00-.904-.529 1.038 1.038 0 00-.904.529c-.184.329-1.067 1.963-1.333 2.406a2.695 2.695 0 01-1.067 1.061l-2.418 1.326a1.023 1.023 0 000 1.794c.336.18 1.973 1.077 2.418 1.326.45.246.82.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.033 1.033 0 001.808 0c.184-.326 1.066-1.963 1.333-2.406a2.702 2.702 0 011.066-1.061c.445-.241 2.082-1.138 2.418-1.326a1.022 1.022 0 000-1.794z"
            ></path>
            <path
                fill="#168FCE"
                d="M101.975 36.987c-.251-.136-1.48-.81-1.816-.99a1.999 1.999 0 01-.8-.796c-.18-.334-.85-1.562-.991-1.806a.777.777 0 00-.679-.395.781.781 0 00-.678.395c-.142.244-.8 1.472-.995 1.806a1.974 1.974 0 01-.8.796c-.336.18-1.565.855-1.816.99a.77.77 0 00-.4.672.765.765 0 00.4.673l1.816.992c.34.181.618.458.8.796.184.331.853 1.562.995 1.804a.773.773 0 00.678.397.78.78 0 00.679-.397c.141-.242.8-1.473.992-1.804.183-.337.46-.613.799-.796l1.816-.992a.768.768 0 000-1.345zM15.975 78.987c-.25-.135-1.48-.81-1.816-.99a1.998 1.998 0 01-.8-.796c-.18-.334-.85-1.562-.991-1.806a.777.777 0 00-.679-.395.781.781 0 00-.678.395c-.142.244-.8 1.472-.995 1.806a1.974 1.974 0 01-.8.796c-.336.18-1.565.855-1.815.99a.769.769 0 00-.401.672.764.764 0 00.4.673l1.816.992c.34.181.618.458.8.796.184.331.853 1.562.995 1.804a.773.773 0 00.678.397.78.78 0 00.679-.397c.141-.242.8-1.473.992-1.804a1.98 1.98 0 01.8-.796l1.815-.992a.77.77 0 00.29-1.063.77.77 0 00-.29-.282zM19.523 126c-.895 0-1.77.264-2.513.758a4.51 4.51 0 00-1.666 2.02 4.479 4.479 0 00.98 4.904 4.536 4.536 0 006.96-.682 4.484 4.484 0 00-.563-5.682A4.534 4.534 0 0019.523 126zM99.338 82c-.462 0-.914.136-1.299.392a2.316 2.316 0 00-.354 3.58 2.342 2.342 0 002.548.504 2.331 2.331 0 001.444-2.15c0-.616-.247-1.208-.685-1.645A2.346 2.346 0 0099.338 82z"
            ></path>
            <path
                fill="#000"
                d="M110.56 70a1.556 1.556 0 00-1.53 1.855 1.55 1.55 0 001.225 1.219 1.569 1.569 0 001.602-.66 1.548 1.548 0 00-.194-1.96A1.566 1.566 0 00110.56 70zM96.56 102a1.563 1.563 0 00-1.442.959 1.544 1.544 0 00.34 1.691 1.562 1.562 0 002.4-.238 1.546 1.546 0 00-.702-2.295 1.565 1.565 0 00-.596-.117zM20.56 96a1.565 1.565 0 00-1.441.958 1.545 1.545 0 00.338 1.691 1.563 1.563 0 002.662-1.097c0-.412-.164-.806-.456-1.097A1.563 1.563 0 0020.56 96z"
            ></path>
            <path
                fill="#D6ECFF"
                d="M21.122 49a3.13 3.13 0 00-1.734.522c-.513.34-.914.826-1.15 1.393a3.089 3.089 0 00.676 3.384 3.124 3.124 0 003.4.672 3.116 3.116 0 001.4-1.144 3.093 3.093 0 00-.388-3.919A3.125 3.125 0 0021.122 49zM103.122 52a3.13 3.13 0 00-1.734.522 3.091 3.091 0 00-.474 4.777 3.131 3.131 0 004.8-.472 3.094 3.094 0 00-.388-3.919 3.123 3.123 0 00-2.204-.908zM102.796 126.104c-.925 0-1.829.273-2.598.784a4.657 4.657 0 00-1.723 2.088 4.634 4.634 0 001.014 5.071 4.683 4.683 0 002.395 1.274 4.705 4.705 0 002.702-.265 4.674 4.674 0 002.098-1.714 4.635 4.635 0 00-.583-5.874 4.693 4.693 0 00-3.305-1.364zm0 12.41a7.826 7.826 0 01-4.331-1.307 7.768 7.768 0 01-2.872-3.482 7.72 7.72 0 01-.443-4.481 7.745 7.745 0 012.133-3.972 7.805 7.805 0 013.992-2.123 7.834 7.834 0 014.504.441 7.798 7.798 0 013.499 2.857 7.73 7.73 0 011.314 4.31 7.738 7.738 0 01-2.285 5.483 7.822 7.822 0 01-5.511 2.274z"
            ></path>
            <path
                fill="#E2048C"
                d="M115.975 109.322a189.644 189.644 0 01-2.418-1.326 2.687 2.687 0 01-1.066-1.061c-.251-.443-1.139-2.077-1.333-2.406a1.03 1.03 0 00-.904-.529 1.033 1.033 0 00-.904.529c-.184.329-1.067 1.963-1.333 2.406a2.696 2.696 0 01-1.067 1.061l-2.418 1.326a1.019 1.019 0 000 1.794c.336.18 1.973 1.077 2.418 1.326.45.246.819.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.033 1.033 0 001.808 0c.184-.326 1.066-1.963 1.333-2.406a2.7 2.7 0 011.066-1.061c.445-.241 2.082-1.138 2.418-1.326a1.028 1.028 0 00.39-1.418 1.035 1.035 0 00-.39-.376zM107.975 13.322a199.073 199.073 0 01-2.418-1.326 2.681 2.681 0 01-1.066-1.061c-.251-.443-1.139-2.077-1.333-2.406a1.034 1.034 0 00-1.428-.387c-.159.093-.29.226-.38.387-.184.329-1.067 1.963-1.333 2.406a2.69 2.69 0 01-1.067 1.06l-2.418 1.327a1.026 1.026 0 00-.532.897 1.02 1.02 0 00.532.897c.336.18 1.973 1.077 2.418 1.326.45.246.82.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.029 1.029 0 00.904.532 1.039 1.039 0 00.904-.532c.184-.326 1.066-1.963 1.333-2.406a2.702 2.702 0 011.066-1.061c.445-.241 2.082-1.138 2.418-1.326a1.026 1.026 0 00.533-.897 1.026 1.026 0 00-.533-.897z"
            ></path>
            <path
                fill="#000"
                d="M5.56 58a1.566 1.566 0 00-1.441.958 1.545 1.545 0 00.338 1.691 1.563 1.563 0 002.662-1.097c0-.412-.164-.806-.456-1.097A1.564 1.564 0 005.56 58z"
            ></path>
            <path fill="#F0F8FF" d="M41 14H79V141H41z"></path>
            <g clipPath="url(#clip0_48_2)">
                <path
                    fill="#D6ECFF"
                    d="M69.125 135.5L77 17v124H35l34.125-5.5z"
                ></path>
                <path
                    fill="#000"
                    d="M86.303 89.492v56.694c0 3.063-.749 3.814-3.788 3.814H29.63c-2.757 0-3.629-.867-3.629-3.603V3.619c0-2.747.88-3.627 3.616-3.627h53.018c2.86 0 3.669.812 3.669 3.69.002 19.024 0 66.78 0 85.81zM28.763 2.765v144.494H83.55V2.765H28.762z"
                ></path>
                <path
                    fill="#000"
                    d="M33.322 89.36V9.204c0-2.056.263-2.325 2.372-2.325h41.039c1.976 0 2.316.327 2.316 2.27v131.719c0 1.916-.358 2.258-2.335 2.258H35.541c-1.898 0-2.22-.319-2.22-2.206V89.36zm2.733-79.74v94.861c2.311.735 4.554 1.317 6.694 2.158 4.307 1.705 8.537 1.621 12.788-.116a23.13 23.13 0 013.455-1.233c4.38-1.023 8.59-.438 12.696 1.373 1.397.614 2.947.872 4.628 1.349v-5.221-54.507c0-.48-.047-1.162-.35-1.407-1.827-1.481-3.759-2.834-5.609-4.286a4.36 4.36 0 00-3.816-.898c-1.367.291-2.743.555-4.127.79-3.232.549-5.346-.582-6.619-3.603a38.46 38.46 0 01-1.317-3.842 4.959 4.959 0 00-3.535-3.55 37.672 37.672 0 01-4.077-1.453c-2.636-1.149-3.666-2.822-3.46-5.698a18.82 18.82 0 01.664-3.742c.67-2.372.118-4.317-1.598-6.12-1.251-1.318-2.203-2.918-3.313-4.373a1.146 1.146 0 00-.687-.458c-.799-.061-1.576-.024-2.417-.024zm0 112.58c2.459.79 4.744 1.418 6.916 2.269a15.896 15.896 0 008.9.846 26.247 26.247 0 004.604-1.46c4.626-1.816 9.225-1.726 13.837.016 1.89.714 3.832 1.281 5.933 1.974v-14.942c-2.427-.79-4.744-1.423-6.963-2.29a16.15 16.15 0 00-9.161-.767 21.998 21.998 0 00-3.978 1.318c-4.833 1.966-9.638 1.9-14.445-.058-1.8-.733-3.582-1.568-5.654-1.426l.01 14.52zm40.188 6.325c-1.927-.551-3.809-.87-5.498-1.618-4.953-2.193-9.797-2.161-14.76 0-4.596 2-9.272 1.845-13.942.063-1.9-.73-3.867-1.283-5.975-1.974v15.342h40.175v-11.813zm.036-95.725a2.19 2.19 0 00-.38-.147c-12.579-.981-21.677-11.025-22.541-22.171-.064-.82-.493-.88-1.126-.878H41.761c1.162 1.344 2.032 2.417 2.973 3.426 1.932 2.062 2.77 4.405 2.108 7.225-.3 1.275-.527 2.57-.74 3.863-.306 1.877.176 2.776 1.958 3.503 1.373.562 2.786 1.033 4.196 1.502a6.834 6.834 0 014.544 4.541c.506 1.492.993 2.992 1.605 4.439.661 1.56 1.581 1.992 3.257 1.758.481-.032.958-.11 1.424-.232 4.522-1.581 8.33-.567 11.48 3.044.424.488 1.036.812 1.703 1.318l.01-11.19zM56.088 9.657c.245 10.806 10.055 20.073 20.136 20.178V9.657H56.088z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_48_2">
                    <path
                        fill="#fff"
                        d="M0 0H60.303V150H0z"
                        transform="translate(26)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Graphics;
