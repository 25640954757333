import React from "react";

function PointOfPurchaseDisplays() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="119"
            height="143"
            fill="none"
            viewBox="0 0 119 143"
        >
            <path
                fill="#168FCE"
                d="M7.799 4.104c-.925 0-1.83.273-2.599.784a4.658 4.658 0 00-1.722 2.088 4.631 4.631 0 001.014 5.071 4.698 4.698 0 005.096 1.009 4.672 4.672 0 002.099-1.714 4.636 4.636 0 00.788-2.585 4.646 4.646 0 00-1.371-3.289 4.694 4.694 0 00-3.305-1.364zm0 12.41a7.823 7.823 0 01-4.332-1.306 7.766 7.766 0 01-2.873-3.48A7.72 7.72 0 01.15 7.244a7.745 7.745 0 012.133-3.972 7.808 7.808 0 013.992-2.124 7.832 7.832 0 014.505.441 7.788 7.788 0 013.499 2.857 7.73 7.73 0 011.314 4.31 7.742 7.742 0 01-2.284 5.482 7.821 7.821 0 01-5.51 2.275zM110.501 41.4a2.098 2.098 0 00-1.484 3.585 2.094 2.094 0 002.288.455 2.104 2.104 0 001.296-1.94 2.102 2.102 0 00-2.1-2.1zm0 5.6a3.502 3.502 0 01-3.434-4.182 3.497 3.497 0 012.75-2.75 3.498 3.498 0 013.158 5.906A3.504 3.504 0 01110.501 47z"
            ></path>
            <path
                fill="#E2048C"
                d="M22.975 68.322a201.308 201.308 0 01-2.418-1.326 2.69 2.69 0 01-1.066-1.061c-.25-.443-1.139-2.077-1.333-2.406a1.033 1.033 0 00-.904-.529 1.039 1.039 0 00-.904.529c-.184.329-1.067 1.963-1.333 2.406a2.689 2.689 0 01-1.067 1.06l-2.418 1.327a1.026 1.026 0 00-.532.897 1.02 1.02 0 00.532.897c.336.18 1.973 1.077 2.418 1.326.45.246.82.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.03 1.03 0 00.904.532 1.037 1.037 0 00.904-.532c.184-.326 1.066-1.963 1.333-2.406a2.705 2.705 0 011.066-1.061c.445-.241 2.083-1.138 2.418-1.326a1.027 1.027 0 00.533-.897 1.02 1.02 0 00-.533-.897z"
            ></path>
            <path
                fill="#168FCE"
                d="M103.975 34.987c-.251-.136-1.48-.81-1.816-.99a2.002 2.002 0 01-.8-.796c-.181-.334-.85-1.562-.991-1.806a.782.782 0 00-1.357 0c-.142.244-.8 1.472-.995 1.806a1.974 1.974 0 01-.8.796c-.336.18-1.565.855-1.816.99a.77.77 0 00-.4.672.765.765 0 00.4.673l1.816.992c.34.181.618.458.8.796.184.331.853 1.562.995 1.804a.773.773 0 00.678.397.78.78 0 00.679-.397c.141-.242.8-1.473.991-1.804.184-.337.462-.613.8-.796l1.816-.992a.768.768 0 000-1.345zM9.975 48.987c-.25-.136-1.48-.81-1.816-.99a1.997 1.997 0 01-.8-.796c-.18-.334-.85-1.562-.991-1.806A.777.777 0 005.689 45a.781.781 0 00-.678.395c-.142.244-.8 1.472-.995 1.806a1.974 1.974 0 01-.8.796c-.336.18-1.565.855-1.815.99a.769.769 0 00-.401.672.764.764 0 00.4.673l1.816.992c.34.181.618.458.8.796.184.331.853 1.562.995 1.804a.773.773 0 00.678.397.78.78 0 00.679-.397c.141-.242.8-1.473.991-1.804.184-.337.462-.613.8-.796l1.816-.992a.77.77 0 00.29-1.063.77.77 0 00-.29-.282zM12.523 111c-.895 0-1.77.264-2.513.758a4.51 4.51 0 00-1.666 2.02 4.479 4.479 0 00.98 4.904 4.536 4.536 0 006.96-.682 4.484 4.484 0 00-.563-5.682A4.534 4.534 0 0012.523 111zM101.338 65c-.462 0-.914.136-1.299.392a2.316 2.316 0 00-.354 3.58 2.345 2.345 0 003.597-.353 2.319 2.319 0 00-.29-2.938 2.347 2.347 0 00-1.654-.681z"
            ></path>
            <path
                fill="#000"
                d="M116.56 23a1.556 1.556 0 00-1.53 1.855 1.55 1.55 0 001.225 1.219 1.569 1.569 0 001.602-.66 1.548 1.548 0 00-.194-1.96A1.566 1.566 0 00116.56 23zM105.56 97a1.564 1.564 0 00-1.442.959 1.548 1.548 0 001.139 2.115 1.56 1.56 0 001.862-1.525A1.55 1.55 0 00105.56 97zM1.56 83a1.565 1.565 0 00-1.441.958 1.545 1.545 0 00.338 1.691 1.563 1.563 0 002.662-1.097c0-.412-.164-.806-.456-1.097A1.564 1.564 0 001.56 83z"
            ></path>
            <path
                fill="#D6ECFF"
                d="M14.122 31a3.13 3.13 0 00-1.734.522c-.513.34-.914.826-1.15 1.393a3.089 3.089 0 00.676 3.384 3.124 3.124 0 003.4.672 3.116 3.116 0 001.4-1.144 3.093 3.093 0 00-.388-3.919A3.125 3.125 0 0014.122 31zM4.122 98a3.13 3.13 0 00-1.734.522c-.513.34-.914.826-1.15 1.393a3.09 3.09 0 00.676 3.384 3.128 3.128 0 003.4.672 3.12 3.12 0 001.4-1.144 3.095 3.095 0 00-.388-3.919A3.125 3.125 0 004.122 98zM106.796 114.104c-.925 0-1.829.273-2.598.784a4.636 4.636 0 00-.709 7.159 4.684 4.684 0 002.395 1.274 4.705 4.705 0 002.702-.265 4.674 4.674 0 002.098-1.714 4.635 4.635 0 00-.583-5.874 4.693 4.693 0 00-3.305-1.364zm0 12.41a7.825 7.825 0 01-4.331-1.307 7.766 7.766 0 01-2.872-3.482 7.72 7.72 0 01-.443-4.481 7.747 7.747 0 012.133-3.972 7.81 7.81 0 013.992-2.123 7.834 7.834 0 014.504.441 7.798 7.798 0 013.499 2.857 7.73 7.73 0 011.314 4.31 7.738 7.738 0 01-2.285 5.483 7.822 7.822 0 01-5.511 2.274z"
            ></path>
            <path
                fill="#E2048C"
                d="M112.975 79.322a199.073 199.073 0 01-2.418-1.326 2.681 2.681 0 01-1.066-1.061c-.251-.443-1.139-2.077-1.333-2.406a1.035 1.035 0 00-1.808 0c-.184.329-1.067 1.963-1.333 2.406a2.69 2.69 0 01-1.067 1.06l-2.418 1.327a1.03 1.03 0 00-.532.897 1.016 1.016 0 00.532.897c.336.18 1.973 1.077 2.418 1.326.45.246.819.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.029 1.029 0 00.904.532 1.039 1.039 0 00.904-.532c.184-.326 1.066-1.963 1.333-2.406a2.702 2.702 0 011.066-1.061c.445-.241 2.082-1.138 2.418-1.326a1.026 1.026 0 00.533-.897 1.026 1.026 0 00-.533-.897zM116.975 6.322a199.362 199.362 0 01-2.418-1.326 2.681 2.681 0 01-1.066-1.061c-.251-.443-1.139-2.077-1.333-2.406a1.034 1.034 0 00-1.428-.387c-.159.093-.29.226-.38.387-.184.329-1.067 1.963-1.333 2.406a2.69 2.69 0 01-1.067 1.06l-2.418 1.327a1.03 1.03 0 00-.532.897 1.016 1.016 0 00.532.897c.336.18 1.973 1.077 2.418 1.326.45.246.819.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.029 1.029 0 00.904.532 1.039 1.039 0 00.904-.532c.184-.326 1.066-1.963 1.333-2.406a2.701 2.701 0 011.066-1.061c.445-.241 2.082-1.138 2.418-1.326a1.026 1.026 0 00.533-.897 1.026 1.026 0 00-.533-.897z"
            ></path>
            <path fill="#F0F8FF" d="M26 6H98V26H26z"></path>
            <path fill="#F0F8FF" d="M30 97H94V140H30z"></path>
            <path
                fill="#D6ECFF"
                d="M85.5 133l7-35 .5 42H45l40.5-7zM91.344 22.667L98.49 6 99 26H50l41.344-3.333z"
            ></path>
            <path
                fill="#000"
                d="M24.5 25.99a4.505 4.505 0 01-4.502-4.5v-14a4.505 4.505 0 014.501-4.5h68a4.503 4.503 0 014.492 4.504v14a4.503 4.503 0 01-4.492 4.496h-68zm43.11 45.581a11.865 11.865 0 002.801-7.663A11.927 11.927 0 0058.5 51.994a11.927 11.927 0 00-11.914 11.914 11.85 11.85 0 002.803 7.663 17.516 17.516 0 00-7.496 12.43H30.999V29.003h54.993v55H75.106a17.503 17.503 0 00-7.495-12.43v-.002zm-18.025-7.663a8.923 8.923 0 018.914-8.914 8.925 8.925 0 018.923 8.914 8.93 8.93 0 01-6.699 8.637c-.734.143-1.477.237-2.224.282a8.925 8.925 0 01-8.914-8.913v-.006zm2.072 9.763a11.921 11.921 0 003.838 1.769c.015 0 2.185.376 2.995.376s2.983-.376 3.013-.39a24.83 24.83 0 001.599-.542l.04-.017a11.89 11.89 0 002.178-1.205 14.448 14.448 0 016.752 10.324H44.915a14.458 14.458 0 016.751-10.311l-.01-.004zm43.832 13.323a1.507 1.507 0 010 3.013H21.5a1.507 1.507 0 010-3.013h73.99zM92 135.495a4.505 4.505 0 01-4.5 4.492H29.492a4.507 4.507 0 01-4.501-4.5V92.994H92v42.501zm.5-106.501a7.507 7.507 0 007.505-7.5v-14A7.51 7.51 0 0092.499 0h-68A7.507 7.507 0 0017 7.5v14a7.507 7.507 0 007.5 7.503h3.499v55h-6.5A4.505 4.505 0 0017 88.504a4.503 4.503 0 004.5 4.5h.498v42.491A7.511 7.511 0 0029.5 143h58.006a7.508 7.508 0 007.501-7.499V92.994h.5a4.502 4.502 0 004.497-4.499 4.505 4.505 0 00-4.5-4.501h-6.499v-54.99l3.494-.01z"
            ></path>
            <path
                fill="#000"
                d="M21.56 45a1.565 1.565 0 00-1.441.958 1.545 1.545 0 00.338 1.691 1.563 1.563 0 002.662-1.097c0-.412-.164-.806-.456-1.097A1.564 1.564 0 0021.56 45z"
            ></path>
        </svg>
    );
}

export default PointOfPurchaseDisplays;
