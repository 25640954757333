import React from "react";

function TradshowSignage() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="119"
            height="150"
            fill="none"
            viewBox="0 0 119 150"
        >
            <path fill="#F0F8FF" d="M45 8H83V136H45z"></path>
            <path
                fill="#D6ECFF"
                d="M73.125 130.456L81 11v125H39l34.125-5.544z"
            ></path>
            <path
                fill="#000"
                d="M96.25 150H20v-37.714h13.236v3.187H23.163v31.341h69.924v-31.341H82.603v-3.187H96.25V150z"
            ></path>
            <path
                fill="#000"
                d="M80.332 3.639c-.936-.09-1.449-.172-1.968-.179-15.064-.076-25.7-.12-40.763-.248-2.139 0-2.366 1.07-2.34 2.785.113 9.648.227 19.296.246 28.944 0 23.35.05 72.885-.057 96.228 0 2.708.81 3.384 3.385 3.358 14.33-.108 24.237-.051 38.593-.07.91 0 1.822-.127 2.93-.21L80.331 3.639zM31.913 126.05V0H83.92v124.14l.101 6.124v7.704h-52.1v-11.917h-.007z"
            ></path>
            <path
                fill="#000"
                d="M75.6 9.38V29.36c-1.158.07-2.183.191-3.208.191-10.844 0-17.266-.038-28.116.07-2.828 0-3.91-.835-3.796-3.823.234-5.283.07-10.592.07-16.404L75.6 9.38zM43.655 25.644h28.261V12.56c-10.477 0-16.323 0-26.572.057a2.189 2.189 0 00-.976.504 2.213 2.213 0 00-.612.917c-.165 3.779-.101 7.564-.101 11.617v-.012zM40.46 45.412v-3.187h35.146l.183 3.187H40.461zM75.631 35.604v3.231H40.48v-3.23H75.63zM40.277 49.019h18.525v3.345h-18.24c-.082-.987-.164-1.975-.285-3.345z"
            ></path>
            <path
                fill="#D6ECFF"
                d="M23.122 11a3.13 3.13 0 00-1.734.522c-.513.34-.914.826-1.15 1.393a3.089 3.089 0 00.676 3.384 3.124 3.124 0 003.4.672 3.116 3.116 0 001.4-1.144 3.093 3.093 0 00-.388-3.919A3.125 3.125 0 0023.122 11z"
            ></path>
            <path
                fill="#168FCE"
                d="M12.799 24.104c-.925 0-1.83.273-2.598.784a4.658 4.658 0 00-1.723 2.088 4.631 4.631 0 001.014 5.071 4.698 4.698 0 005.096 1.009 4.672 4.672 0 002.099-1.714 4.636 4.636 0 00.788-2.585 4.646 4.646 0 00-1.371-3.289 4.694 4.694 0 00-3.305-1.364zm0 12.41a7.823 7.823 0 01-4.332-1.306 7.766 7.766 0 01-2.873-3.48 7.72 7.72 0 01-.445-4.483 7.745 7.745 0 012.133-3.972 7.809 7.809 0 013.992-2.124 7.833 7.833 0 014.505.441 7.788 7.788 0 013.499 2.857 7.73 7.73 0 011.314 4.31 7.742 7.742 0 01-2.284 5.482 7.821 7.821 0 01-5.51 2.275zM111.501 27.4a2.098 2.098 0 00-1.484 3.585 2.094 2.094 0 002.288.455 2.104 2.104 0 001.296-1.94 2.102 2.102 0 00-2.1-2.1zm0 5.6a3.502 3.502 0 01-3.434-4.182 3.497 3.497 0 012.75-2.75 3.498 3.498 0 013.158 5.906A3.504 3.504 0 01111.501 33zM7.501 131.4a2.105 2.105 0 00-1.94 1.297 2.098 2.098 0 003.686 1.969A2.098 2.098 0 007.5 131.4zm0 5.6a3.505 3.505 0 01-3.234-2.16 3.499 3.499 0 014.572-4.574A3.505 3.505 0 0111 133.5a3.502 3.502 0 01-3.499 3.5z"
            ></path>
            <path
                fill="#E2048C"
                d="M11.975 105.322a191.74 191.74 0 01-2.418-1.326 2.695 2.695 0 01-1.066-1.061c-.25-.443-1.139-2.077-1.333-2.406a1.028 1.028 0 00-.904-.529 1.038 1.038 0 00-.904.529c-.184.329-1.067 1.963-1.333 2.406a2.695 2.695 0 01-1.067 1.061l-2.418 1.326a1.023 1.023 0 000 1.794c.336.18 1.973 1.077 2.418 1.326.45.246.82.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.033 1.033 0 001.808 0c.184-.326 1.066-1.963 1.333-2.406a2.702 2.702 0 011.066-1.061c.445-.241 2.082-1.138 2.418-1.326a1.022 1.022 0 000-1.794z"
            ></path>
            <path
                fill="#168FCE"
                d="M99.975 40.987c-.25-.136-1.48-.81-1.816-.99a1.998 1.998 0 01-.8-.796c-.18-.334-.85-1.562-.991-1.806a.777.777 0 00-.679-.395.781.781 0 00-.678.395c-.142.244-.8 1.472-.995 1.806a1.974 1.974 0 01-.8.796c-.336.18-1.565.855-1.816.99a.77.77 0 00-.4.672.765.765 0 00.4.673l1.816.992c.34.181.618.458.8.796.184.331.853 1.562.995 1.804a.773.773 0 00.678.397.78.78 0 00.679-.397c.141-.242.8-1.473.992-1.804a1.98 1.98 0 01.8-.796l1.815-.992a.768.768 0 000-1.345zM15.975 81.987c-.25-.135-1.48-.81-1.816-.99a1.998 1.998 0 01-.8-.796c-.18-.334-.85-1.562-.991-1.806a.777.777 0 00-.679-.395.781.781 0 00-.678.395c-.142.244-.8 1.472-.995 1.806a1.974 1.974 0 01-.8.796c-.336.18-1.565.855-1.815.99a.769.769 0 00-.401.672.764.764 0 00.4.673l1.816.992c.34.181.618.458.8.796.184.331.853 1.562.995 1.804a.773.773 0 00.678.397.78.78 0 00.679-.397c.141-.242.8-1.473.992-1.804a1.98 1.98 0 01.8-.796l1.815-.992a.77.77 0 00.29-1.063.77.77 0 00-.29-.282zM112.975 132.987c-.251-.135-1.48-.809-1.816-.99a1.996 1.996 0 01-.8-.796c-.181-.334-.85-1.562-.991-1.806a.778.778 0 00-.679-.395.785.785 0 00-.678.395c-.142.244-.8 1.472-.995 1.806a1.966 1.966 0 01-.8.796c-.336.181-1.565.855-1.815.99a.761.761 0 00-.293 1.063c.07.118.171.216.293.282l1.815.992c.34.181.618.458.8.796.184.331.853 1.562.995 1.804a.778.778 0 001.357 0c.141-.242.8-1.473.991-1.804a1.99 1.99 0 01.8-.796l1.816-.992a.768.768 0 000-1.345zM102.523 55a4.54 4.54 0 00-2.513.758 4.505 4.505 0 00-1.666 2.02 4.48 4.48 0 00.98 4.904 4.542 4.542 0 004.928.975A4.515 4.515 0 00106.284 62a4.484 4.484 0 00-.562-5.682A4.537 4.537 0 00102.523 55zM101.338 82c-.462 0-.914.136-1.299.392a2.316 2.316 0 00-.354 3.58 2.345 2.345 0 003.597-.353 2.319 2.319 0 00-.29-2.938 2.347 2.347 0 00-1.654-.681z"
            ></path>
            <path
                fill="#000"
                d="M112.56 70a1.556 1.556 0 00-1.53 1.855 1.55 1.55 0 001.225 1.219 1.569 1.569 0 001.602-.66 1.548 1.548 0 00-.194-1.96A1.566 1.566 0 00112.56 70zM98.56 102a1.563 1.563 0 00-1.442.959 1.544 1.544 0 00.34 1.691 1.562 1.562 0 002.4-.238 1.541 1.541 0 00.143-1.456A1.56 1.56 0 0098.56 102zM12.56 120a1.562 1.562 0 00-1.441.958 1.546 1.546 0 00.338 1.691 1.564 1.564 0 002.4-.235 1.545 1.545 0 00-.194-1.959A1.564 1.564 0 0012.56 120z"
            ></path>
            <path
                fill="#D6ECFF"
                d="M23.122 49a3.13 3.13 0 00-1.734.522c-.513.34-.914.826-1.15 1.393a3.089 3.089 0 00.676 3.384 3.124 3.124 0 003.4.672 3.116 3.116 0 001.4-1.144 3.093 3.093 0 00-.388-3.919A3.125 3.125 0 0023.122 49z"
            ></path>
            <path
                fill="#E2048C"
                d="M117.975 109.322a189.644 189.644 0 01-2.418-1.326 2.687 2.687 0 01-1.066-1.061c-.251-.443-1.139-2.077-1.333-2.406a1.03 1.03 0 00-.904-.529 1.033 1.033 0 00-.904.529c-.184.329-1.067 1.963-1.333 2.406a2.696 2.696 0 01-1.067 1.061l-2.418 1.326a1.019 1.019 0 000 1.794c.336.18 1.973 1.077 2.418 1.326.45.246.819.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.033 1.033 0 001.808 0c.184-.326 1.066-1.963 1.333-2.406a2.7 2.7 0 011.066-1.061c.445-.241 2.082-1.138 2.418-1.326a1.028 1.028 0 00.39-1.418 1.035 1.035 0 00-.39-.376zM109.975 13.322a199.073 199.073 0 01-2.418-1.326 2.681 2.681 0 01-1.066-1.061c-.251-.443-1.139-2.077-1.333-2.406a1.034 1.034 0 00-1.428-.387c-.159.093-.29.226-.38.387-.184.329-1.067 1.963-1.333 2.406a2.69 2.69 0 01-1.067 1.06l-2.418 1.327a1.026 1.026 0 00-.532.897 1.02 1.02 0 00.532.897c.336.18 1.973 1.077 2.418 1.326.45.246.819.614 1.067 1.061.25.443 1.138 2.08 1.333 2.406a1.029 1.029 0 00.904.532 1.039 1.039 0 00.904-.532c.184-.326 1.066-1.963 1.333-2.406a2.702 2.702 0 011.066-1.061c.445-.241 2.082-1.138 2.418-1.326a1.026 1.026 0 00.533-.897 1.026 1.026 0 00-.533-.897z"
            ></path>
            <path
                fill="#000"
                d="M7.56 58a1.566 1.566 0 00-1.441.958 1.545 1.545 0 00.338 1.691 1.563 1.563 0 002.662-1.097c0-.412-.164-.806-.456-1.097A1.564 1.564 0 007.56 58z"
            ></path>
            <path
                fill="#D6ECFF"
                d="M19.5 98.8a2.7 2.7 0 100 5.4 2.7 2.7 0 000-5.4zm0 7.2a4.504 4.504 0 01-4.158-2.778A4.5 4.5 0 1119.5 106z"
            ></path>
        </svg>
    );
}

export default TradshowSignage;
